<template>
  <allow-only :roles="roles => [roles.admin]" @mounted="mounted">
    <div class="mb-2">
      <a-button @click="$router.back()">
        Regresar
      </a-button>
    </div>
    <div class="flex">
      <div class="w-full">
        <form @submit.prevent="(isUpdating ? update(section) : store(section)).then(() => $router.push({ name: 'sections' }))" @keydown="removeError($event.target.id)">
          <h3 class="my-4 text-2xl">{{ isUpdating ? 'Actualizar' : 'Crear' }} sección</h3>
          <a-card>
            <div class="flex flex-wrap -mx-2">
              <div class="w-full md:w-1/2 lg:w-1/2 px-2">
                <a-input
                  label="Sección"
                  required
                  class="mb-4"
                  id="letter_code"
                  :error="error('letter_code', { size: (size) => `solo ${size} carácter` })"
                  v-model="section.letter_code"/>

                <a-input
                  label="Límite de varones"
                  required
                  class="mb-4"
                  id="males_limit"
                  type="number"
                  :error="error('males_limit')"
                  v-model.number="section.males_limit"/>

                <a-input
                  label="Límite de hembras"
                  required
                  class="mb-4"
                  id="females_limit"
                  type="number"
                  :error="error('females_limit')"
                  v-model.number="section.females_limit"/>
              </div>
              <div class="w-full md:w-1/2 lg:w-1/2 px-2" v-if="!isUpdating">
                <a-select
                  label="Especialidad"
                  class="mb-4"
                  @input="removeError('specialization_id')"
                  :loading="loadingSpecialization"
                  :source="specializations.map(el => ({ label: el.title, value: el.id }))"
                  :error="error('specialization_id', { attribute: 'especialidad' })"
                  v-model="section.specialization_id"/>

                <a-select
                  label="Período"
                  required
                  class="mb-4"
                  @input="removeError('period_id')"
                  :loading="loadingPeriods"
                  :source="periods.map(el => ({ label: `${el.year}-${el.number}`, value: el.id }))"
                  :error="error('period_id')"
                  v-model="section.period_id"/>

                <a-select
                  filterable
                  required
                  class="mb-4"
                  label="Semestre"
                  @input="removeError('semester_id')"
                  :loading="loadingSemesters"
                  :source="mappedSemesters"
                  :error="error('semester_id')"
                  v-model="section.semester_id"/>

              </div>
            </div>

            <template #footer>
              <a-button class="mr-2" type="submit" :disabled="!allLoadingsFinished" :loading="loading">
                {{ isUpdating ? 'Actualizar cambios' : 'Guardar cambios' }}
              </a-button>

              <a-button outlined :to="{ name: 'sections' }">
                Cancelar
              </a-button>
            </template>
          </a-card>
        </form>
      </div>
    </div>
  </allow-only>
</template>

<script>
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex';
export default {
    data: () => ({
        section: {
            letter_code: null,
            males_limit: null,
            females_limit: null,
            specialization_id: 0,
            period_id: null,
            semester_id: null,
            filled: false
        },
        isUpdating: false
    }),
    computed: {
        ...mapGetters({
            hasError: 'hasError',
            error: 'error',
            semestersBySpecialization: 'semesters/semestersBySpecialization'
        }),
        ...mapState({
            sections: state => state.sections.pagination.data,
            currentSectionOnState: state => state.sections.section,
            periods: state => state.periods.all,
            specializations: state => state.specializations.all,
            semesters: state => state.semesters.all,
            loadingPeriods: state => state.periods.loading,
            loadingSpecialization: state => state.specializations.loading,
            loadingSemesters: state => state.semesters.loading,
            loading: state => state.sections.loading,
            loadingCourses: state => state.courses.loading,
            courses: state => state.courses.all,
            loadingTeachers: state => state.teachers.loading,
            teachers: state => state.teachers.all
        }),
        allLoadingsFinished() {
            return !this.loadingPeriods && !this.loadingSpecialization && !this.loadingSemesters
        },
        mappedSemesters() {
            return this.semestersBySpecialization(this.section.specialization_id)
                .map(el => ({ label: `${el.number}: ${el.pensum.name}`, value: el.id }))
        }
    },
    methods: {
        ...mapActions({
            fetchSection: 'sections/fetch',
            store: 'sections/store',
            update: 'sections/update',
            removeError: 'removeError',
            fetchPeriods: 'periods/index',
            fetchSpecializations: 'specializations/fetchSpecializations',
            fetchSemesters: 'semesters/fetchSemesters',
            fetchCourses: 'courses/index',
            fetchTeachers: 'teachers/index'
        }),
        ...mapMutations({
            setNavbarTitle: 'SET_NAVBAR_TITLE'
        }),
      mounted () {
        const id = this.$route.query.update
        this.fetchPeriods({ limit: 0 })
        this.fetchSpecializations({ limit: 0 })
        this.fetchSemesters({ limit: 0, with: 'pensum' })
        this.fetchCourses({ limit: 0 })
        this.fetchTeachers({ limit: 0, enabled: true })

        if (id) {
          this.isUpdating = true

          const found = this.sections.find(section => section.id === parseInt(id))

          if (found) {
            this.section = {...found}
            this.setNavbarTitle(['Listado de secciones', found.letter_code, 'Editar sección'])
          } else {
            this.fetchSection(id)
          }
        }
      }
    },
    watch: {
        currentSectionOnState(val) {
            this.section = {...val}
        },
        pensums(val) {
            if (this.isUpdating) {
                let found = val.find(pensum => pensum.id === this.section.pensum_id)
                if (found) {
                    this.section.pensum_id = found.id
                }
            }
        }
    },
}
</script>
